import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./pages.css";
import logo from "../images/FH-logo.svg";
import ReactGA from "react-ga4";
import logos from "../images/logos.png";
const PaymentSucess = () => {
  useEffect(() => {
    document.title = 'FH Artist Event: Payment';
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search})
  }, []);
  const Location = useLocation();
  return (
    <div className="Footercontainer">
      <div className="content">
        <iframe
          title="fh-payment"
          style={{ height: "400px", width:'100%' }}
          src={Location.state.finalString}
          id='payment-iframe'
        ></iframe>{" "}
      </div>
      <hr className="border"></hr>
      <img src={logo} alt="Food for Hungry" width="130" />
      <img src={logos} alt="logos" width="100%" />
      <div className="event"></div>
    </div>
  );
};

export default PaymentSucess;
