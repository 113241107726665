import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import "./pages.css";
import Header from "../components/Header";
const Links = () => {
  const Navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("isLogged")) {
      Navigate("/login");
    }
  }, []);
  return (
    <>
      <Header showLogOut={true} />
      <h2 className="headings">Admin Management</h2>
      <hr className="links-hr"></hr>
      <div className="link">
        <h5 className='links' onClick={()=> Navigate('/redis')}>Event Management</h5>
        <h5 className='links' onClick={()=> Navigate('/csv')}>Export Sponsorship CSV</h5>
      </div>
    </>
  );
};

export default Links;
