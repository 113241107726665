import React, { useMemo, useState, useRef, useEffect } from "react";
// import ReactDOM from 'react-dom';
import "./App.css";
import Header from "./components/Header.js";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import ChildInformation from "./pages/ChildInformation.jsx";
import SponsorInformation from "./pages/SponsorInformation.jsx";
import SponserSucess from "./pages/SponserSucess.jsx";
import PaymentSucess from "./pages/PaymentSucess.jsx";
import Counter from "./components/Counter";
import LoginPage from "./pages/Loginpage.jsx";
import RedisPage from "./pages/Redis.jsx";
import Links from "./pages/Links";
import EventManagement from "./pages/EventManagement.jsx";
import SponsorshipCsv from "./pages/SponsorshipCsv.jsx";
import History from "./pages/History.jsx";
import QRCodeHistory from "./pages/QRCodeHistory.jsx";
import JWTInterceptor from "./service";
function App() {
  const [eventLogo, setEventLogo] = useState("");
  const Location = useLocation();
  const TRACKING_ID = "G-PYMEPXD7PK"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  // G-PYMEPXD7PK
  const FormURL = (url) => {
    if (url && url.length !== 0) {
      Location.search = url;
    } else {
      url = Location.pathname.substring(Location.pathname.lastIndexOf("?"));
      Location.search = url;
    }
    return url;
  };
  const Query = useMemo(
    () => new URLSearchParams(FormURL(Location.search)),
    [Location.search]
  );
  useEffect(() => {
    if (Query.get("SourceCode")) {
      GetChild(Query.get("SourceCode"));
    } else if (Location.pathname === "/checkout/") {
      let flag = Location.search.split("?");
      let decoded = decodeURIComponent(flag[2]);
      let decodedone = decodeURIComponent(flag[1]);
      if (
        flag[1].indexOf("response") !== -1 ||
        flag[1].indexOf("declined") !== -1
      ) {
        decoded = JSON.parse(decoded);
        GetChild(decoded[0].transactionsourcecode);
      } else {
        decoded = decodeURIComponent(decodedone).split("&");
        let sourcecode = decoded[4].split("=")[1];
        GetChild(sourcecode);
      }
    }
  }, []);
  const GetChild = (code) => {
    let url = `GetEventLogo?SourceCode=${code}`;
    JWTInterceptor.get(url, {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY
      }
    })
      .then((res) => {
        if (res.data.serviceResponse.success) {
          setEventLogo(res.data.serviceResponse.eventLogoURL);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search
    });
  });

  return (
    <div className="App">
      {window.location.pathname !== "/login" &&
        window.location.pathname !== "/redis" &&
        window.location.pathname !== "/csv" &&
        window.location.pathname !== "/history" &&
        window.location.pathname !== "/qrhistory" &&
        !window.location.pathname.includes("/events") &&
        window.location.pathname !== "/links" && (
          <Header eventLogo={eventLogo} />
        )}

      <Routes>
        <Route path="/" element={<ChildInformation />} />
        <Route path="/sponsor" element={<SponsorInformation />} />
        <Route path="/checkout" element={<SponserSucess />} />
        <Route path="/payment" element={<PaymentSucess />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/redis" element={<RedisPage />} />
        <Route path="/links" element={<Links />} />
        <Route path="/csv" element={<SponsorshipCsv />} />
        <Route path="/history" element={<History />} />
        <Route path="/qrhistory" element={<QRCodeHistory />} />
        <Route path="/redis/events/:id?/:mode" element={<EventManagement />} />
      </Routes>
      {window.location.pathname !== "/redis" &&
        window.location.pathname !== "/checkout/" &&
        window.location.pathname !== "/csv" &&
        window.location.pathname !== "/history" &&
        window.location.pathname !== "/qrhistory" &&
        !window.location.pathname.includes("/events") &&
        window.location.pathname !== "/links" &&
        window.location.pathname !== "/login" && (
          <p>
            <span>
              {" "}
              <Counter className="counter" />{" "}
            </span>
          </p>
        )}
    </div>
  );
}

export default App;
