import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../pages/pages.css";
function Counter(props) {
  const [Timer, setTimer] = useState(300000); // 15mins to ms
  const History = useNavigate();
  const Location = useLocation();
  const FormURL = (url) => {
    if (url && url.length !== 0) {
      Location.search = url;
    } else {
      url = Location.pathname.substring(Location.pathname.lastIndexOf("?"));
      Location.search = url;
    }
    return url;
  };
  const Query = useMemo(
    () => new URLSearchParams(FormURL(Location.search)),
    [Location.search]
  );
  function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  }
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  useEffect(() => {
    setInterval(() => {
      setTimer((prev) => prev - 1000); //minizing 1s
    }, 1000);
  }, []);
  useEffect(() => {
    if (Timer <= 0) {
      if (window.location.pathname === "/") {
        document.location.reload();
      } else {
        if (Query.get("PledgeableId")) {
          History(
            `/?SourceCode=${Query.get("SourceCode")}&PledgeableId=${Query.get(
              "PledgeableId"
            )}`
          );
        } else {
          History(`/?SourceCode=${Query.get("SourceCode")}`);
        }
      }

      // History("/");
      setTimer(300000);
    }
    if (localStorage.getItem("reset") === "utbas") {
      // console.log(props.resetTime);
      // props.ResetTimeAgain();
      localStorage.setItem("reset", "itnjsfvn");
      setTimer(300000);
    }
  }, [Timer]);
  return (
    localStorage.getItem("loaded") !== "nope" && (
      <div className="timer">
        <label className="minute-lbl counter">
          TIME LEFT: {convertMsToTime(Timer)}
        </label>
      </div>
    )
  );
}
export default Counter;
