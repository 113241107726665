import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import data from "../Data/GridData.json";
import "./pages.css";
import Header from "../components/Header";
import JWTInterceptor from "../service";
import Loader from "../components/Loader";
import Paginator from "../components/Paginator";
import { MdOutlineManageHistory } from "react-icons/md";
import altImage from "../images/notFound.jpg";
const Redis = () => {
  const Navigate = useNavigate();
  const [ApiResponse, setApiResponse] = useState(false);
  const [ApiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [AllEvents, setAllEvents] = useState([]);
  const [TotalEvents, setTotalEvents] = useState([]);
  const [SerachBoxData, setSerachBoxData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [erroColorr, seterroColorr] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("isLogged")) {
      Navigate("/login");
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    let eventListBody = {
      eventMasterID: "0",
      token: localStorage.getItem("tokne")
    };
    JWTInterceptor.post("/GetEvent", eventListBody, {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET"
      }
    })
      .then((res) => {
        if (res.data.serviceResponse.success) {
          setAllEvents(res.data.serviceResponse.lstEvent);
          setTotalEvents(res.data.serviceResponse.lstEvent);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  let PageSize = 10;
  const GridDataPagination = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return AllEvents.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, AllEvents]);
  useEffect(() => {
    let filterData = TotalEvents.filter((ele) => {
      return (
        ele.eventName.toLowerCase().includes(SerachBoxData.toLowerCase()) ||
        ele.sourceCode.toLowerCase().includes(SerachBoxData.toLowerCase())
      );
    });
    setAllEvents(filterData);
  }, [SerachBoxData]);
  const timer15sec = new Promise((resolve, reject) => {
    setTimeout(resolve, 5000, "overtime");
  });
  const load = async (sourcevalue) => {
    try {
      setLoading(true);
      let postBody = {
        sourceCode: sourcevalue,
        token: localStorage.getItem("tokne")
      };
      const res = await Promise.race([
        JWTInterceptor.post("/LoadChildInRedis", postBody, {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET"
          }
        }),
        new Promise((resolve, reject) => {
          // Reject after 5 seconds
          setTimeout(() => reject(new Error("Request timed out")), 5000);
        })
      ]);
      // let res = JWTInterceptor.post("/LoadChildInRedis", postBody, {
      //   headers: {
      //     "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
      //     "Content-Type": "application/json",
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET"
      //   }
      // });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      if (res.data.serviceResponse.success) {
        seterroColorr(false);
        setLoading(false);
        setApiResponse(true);
        setApiMessage(`Checkout Batch ${sourcevalue} is loaded in Redis`);
      } else {
        seterroColorr(true);
        setApiResponse(true);
        setApiMessage(res.data.serviceResponse.message);
      }
    } catch (err) {
      setApiResponse(true);
      setApiMessage(
        "Please check Redis Load/Clear History to know the progress."
      );

      // console.log("err", err);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setApiResponse(false);
        setApiMessage("");
      }, 10000);
    }
  };
  const LoadQR = async (sourcevalue,IsGeneral) => {
    try {
      setLoading(true);
      let postBody = {
        sourceCode: sourcevalue,
        token: localStorage.getItem("tokne"),
        IsGeneral: IsGeneral
      };
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      const res = await Promise.race([
        JWTInterceptor.post("/GetChildQRCode", postBody, {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET"
          }
        }),
        new Promise((resolve, reject) => {
          // Reject after 5 seconds
          setTimeout(() => reject(new Error("Request timed out")), 5000);
        })
      ]);
      // let res = JWTInterceptor.post("/LoadChildInRedis", postBody, {
      //   headers: {
      //     "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
      //     "Content-Type": "application/json",
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET"
      //   }
      // });

      if (res.data.serviceResponse.success) {
        seterroColorr(false);
        setLoading(false);
        setApiResponse(true);
        setApiMessage(`QR Code generated successfully.`);
      } else {
        seterroColorr(true);
        setApiResponse(true);
        setApiMessage(res.data.serviceResponse.message);
      }
    } catch (err) {
      seterroColorr(false);
      setApiResponse(true);
      setApiMessage(
        "Please check the QR Code Generation History to know the progress and download."
      );

      // console.log("err", err);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setApiResponse(false);
        setApiMessage("");
      }, 10000);
    }
  };
  const Unload = (sourcevalue) => {
    setLoading(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    let postBody = {
      sourceCode: sourcevalue,
      token: localStorage.getItem("tokne")
    };
    JWTInterceptor.post("/ClearChildInRedis", postBody, {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET"
      }
    })
      .then((res) => {
        setLoading(false);
        if (res.data.serviceResponse.success) {
          seterroColorr(false);
          setApiResponse(true);
          setApiMessage(`Checkout Batch ${sourcevalue} is cleared from Redis`);
        } else {
          seterroColorr(true);
          setApiResponse(true);
          setApiMessage(res.data.serviceResponse.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setApiResponse(false);
          setApiMessage("");
        }, 10000);
      });
  };
  const SearchHandler = (e) => {
    setSerachBoxData(e.target.value);
    setCurrentPage(1);
  };
  const errorImage = (err) => {
    err.target.src = altImage;
  };
  return (
    <div id="redis">
      <Loader show={loading} />
      <Header showLogOut={true} />
      <h2 className="headings">Event Management</h2>
      <hr className="links-hr"></hr>
      {ApiResponse && (
        <div className={erroColorr ? "api-response-red" : "api-response"}>
          {ApiMessage}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 10px 5px 10px"
        }}
      >
        <button
          className="load-unload"
          onClick={() => Navigate("events/create")}
        >
          Add Event
        </button>
        <div className="history" onClick={() => Navigate("/qrhistory")}>
          <MdOutlineManageHistory /> QR Code Generation History
        </div>
      </div>
      <div
        className="history"
        style={{ textAlign: "end", marginRight: "20px", marginBottom: "10px" }}
        onClick={() => Navigate("/history")}
      >
        <MdOutlineManageHistory /> Redis Load/Clear History
      </div>
      <div style={{ textAlign: "end", marginRight: "10px" }}>
        <input
          type="text"
          placeholder="Search"
          onChange={SearchHandler}
          style={{ padding: "5px" }}
        ></input>
      </div>

      <div className="grid">
        <div>
          <div className="inner-grid grid-heading">
            <div>Checkout Batch</div>
            <div>Event Name</div>
            <div>Event Logo</div>
            <div>Action</div>
          </div>
        </div>
        {GridDataPagination.map((ele, index) => {
          return (
            <div className="inner-grid" key={ele["sourceCode"]}>
              <div>{ele["sourceCode"]}</div>
              <div>{ele["eventName"]}</div>
              <div>
                {ele["eventLogoURL"] === "" ? (
                  <div style={{ margin: "auto" }}>-</div>
                ) : (
                  <img
                    src={ele["eventLogoURL"]}
                    height="75px"
                    className="imagegrid"
                  ></img>
                )}
              </div>
              <div className="action-btn">
                <button
                  className="load-unload"
                  onClick={() =>
                    Navigate(`events/${ele["eventMasterID"]}/edit`)
                  }
                >
                  Edit
                </button>
                <button
                  className="load-unload"
                  onClick={() => load(ele["sourceCode"])}
                >
                  Load to Redis
                </button>
                <button
                  className="load-unload"
                  onClick={() => Unload(ele["sourceCode"])}
                >
                  {" "}
                  Clear from Redis
                </button>
                <button
                  className="load-unload"
                  onClick={() => LoadQR(ele["sourceCode"],true)}
                >
                  {" "}
                  Generate General QR Code
                </button>
                <button
                  className="load-unload"
                  onClick={() => LoadQR(ele["sourceCode"],false)}
                >
                  {" "}
                  Generate Child QR Code
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <Paginator
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={AllEvents.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default Redis;
