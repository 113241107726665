import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useMemo } from "react";
import data from "../Data/GridData.json";
import "./pages.css";
import Header from "../components/Header";
import Loader from "../components/Loader";
import JWTInterceptor from "../service";
import { AiFillDelete } from "react-icons/ai";
const EventManagement = () => {
  const params = useParams();
  const Navigate = useNavigate();
  const [SourceCodeValue, setSourceCodeValue] = useState("");
  const [EvenentName, setEvenentName] = useState("");
  const [SourceCodeError, setSourceCodeError] = useState(false);
  const [EvenentNameError, setEvenentNameError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let eventListBody = {
          eventMasterID: params.id,
          token: localStorage.getItem("tokne")
        };
        let particularData = await JWTInterceptor.post(
          "/GetEvent",
          eventListBody,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET"
            }
          }
        );
        setSourceCodeValue(
          particularData.data.serviceResponse.lstEvent[0]["sourceCode"]
        );
        setEvenentName(
          particularData.data.serviceResponse.lstEvent[0]["eventName"]
        );
        if (
          particularData.data.serviceResponse.lstEvent[0]["eventLogoURL"] !== ""
        ) {
          setFile(
            particularData.data.serviceResponse.lstEvent[0]["eventLogoURL"]
          );
          setFileName(
            particularData.data.serviceResponse.lstEvent[0]["eventLogoURL"]
          );
        }
      } catch (err) {
        console.log(err, "err");
      } finally {
        setLoading(false);
      }
    }

    if (params.mode === "edit") {
      fetchData();
    }
  }, []);
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Checking if the file type is allowed or not
    const allowedTypes = ["image/jpeg", "image/png"];
    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile?.type)) {
        setIsError(true);
        setErrorMsg("Only JPEG, PNG images are allowed.");
        setFile();
        return;
      }
      if (selectedFile.size > 1000000) {
        setIsError(true);
        setErrorMsg("Max size 1MB allowed");
        setFile();
        return;
      }
      setFile(URL.createObjectURL(e.target.files[0]));
      setFileName(selectedFile.name);
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setImage(reader.result); //base64encoded string
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    } else {
      setIsError(true);
      setErrorMsg("Required");
      setFile();
      return;
    }

    setIsError(false);
  };

  const FileOnClick = () => {
    setIsError(false);
  };

  const SourceCodeChange = (e) => {
    setSourceCodeValue(e.target.value);
    if (e.target.value === "") {
      setSourceCodeError(true);
    } else {
      setSourceCodeError(false);
    }
  };
  const EventNameChange = (e) => {
    setEvenentName(e.target.value);
    if (e.target.value === "") {
      setEvenentNameError(true);
    } else {
      setEvenentNameError(false);
    }
  };
  const formValidation = () => {
    if (SourceCodeValue === "") {
      setSourceCodeError(true);
    } else {
      setSourceCodeError(false);
    }

    if (EvenentName === "") {
      setEvenentNameError(true);
    } else {
      setEvenentNameError(false);
    }
    if (!file) {
      setIsError(true);
      setErrorMsg("Required");
    } else {
      setIsError(false);
    }
    if (EvenentName === "" || SourceCodeValue === "") {
      return false;
    }
    return true;
  };
  const CreateUpadate = async () => {
    if (!formValidation() || SourceCodeError || EvenentNameError) {
      console.log("have error");
    } else {
      try {
        setLoading(true);
        let updateEvent = {
          eventMasterID: params.mode === "edit" ? params.id : "0",
          sourceCode: SourceCodeValue,
          eventName: EvenentName,
          // logoFileName: '',
          // fileByteArray: '',
          token: localStorage.getItem("tokne")
        };
        if (fileName !== "") {
          if (fileName.includes("https")) {
            updateEvent.logoFileName = fileName;
            updateEvent.fileBase64String = "";
          } else {
            updateEvent.logoFileName = fileName;
            updateEvent.fileBase64String = image.split(",")[1];
          }
        } else {
          updateEvent.logoFileName = "";
          updateEvent.fileBase64String = "";
        }
        let apiRes = await JWTInterceptor.post("/SaveEvent", updateEvent, {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET"
          }
        });
        if (apiRes.data.serviceResponse.success) {
          Navigate("/redis");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  const RemoveImage = () => {
    setFile();
    setFileName("");
    setImage("");
  };
  return (
    <>
      <Header showLogOut={true} />
      <Loader show={loading} />
      <h2 className="headings">
        {params.mode === "create" ? "Create Event" : "Edit Event"}
      </h2>
      <hr className="links-hr"></hr>
      <div>
        <label className="load-label">
        Checkout Batch <span style={{ color: "red" }}>*</span>
        </label>
        <input
          name="sourceCode"
          style={{ width: "95%" }}
          className={`load-input ${SourceCodeError && "input-err"}`}
          placeholder="Enter Checkout Batch*"
          value={SourceCodeValue}
          onChange={(e) => {
            SourceCodeChange(e);
          }}
        />
        {SourceCodeError && <h5 style={{ color: "red" }}>Required</h5>}
      </div>
      <div>
        <label className="load-label">
          Event Name <span style={{ color: "red" }}>*</span>
        </label>
        <input
          name="sourceCode"
          style={{ width: "95%" }}
          className={`load-input ${EvenentNameError && "input-err"}`}
          placeholder="Event Name*"
          value={EvenentName}
          onChange={(e) => {
            EventNameChange(e);
          }}
          onClick={FileOnClick}
        />
        {EvenentNameError && <h5 style={{ color: "red" }}>Required</h5>}
      </div>
      <div>
        <label className="load-label">Event Logo</label>
        <input
          // className={`${isError && "input-err"}`}
          type="file"
          style={{ display: "block", marginLeft: "1rem" }}
          onChange={onFileChange}
          accept="image/*"
        ></input>

        {file && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              alignItems: "center"
            }}
          >
            <img src={file} width="100px" height="100px" />
            <AiFillDelete
              onClick={RemoveImage}
              title="Delete Event Logo"
              style={{ height: "2rem", width: "2rem", cursor: "pointer",paddingLeft:"10px" }}
            />
          </div>
        )}
        {/* {isError && <h5 style={{ color: "red" }}>{errorMsg}</h5>} */}
      </div>
      <div className="sign">
        <button onClick={CreateUpadate}>Save</button>
        {/* <a href="#">Need Account?</a> */}
      </div>
    </>
  );
};

export default EventManagement;
