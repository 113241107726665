import React from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import logo from "../images/FH-logo.svg";
import newLogo from "../images/email-50-purple-year.png";
import itstime from "../images/its-time-logo.png";
import reunion from "../images/Kirk-Franklin-The-Reunion-Tour-LOGO-2023.jpg";
import { AiOutlineLogin } from "react-icons/ai";
const Header = (props) => {
  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem("isLogged");
    navigate("/login");
  };
  return (
    <div className="headerContainer">
      <img src={logo} alt="Food for Hungry" width="50%" />
      {props.showLogOut ? (
        <AiOutlineLogin
          style={{ height: "2rem", width: "2rem", cursor: "pointer" }}
          title="Log Out"
          onClick={Logout}
        />
      ) : props.eventLogo !== "" ? (
        <img src={props.eventLogo} width="30%" />
      ) : (
        <div></div>
      )}
      {/* <div className="event"></div> */}
    </div>
  );
};

export default Header;
