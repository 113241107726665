import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import  loadingImg from '../images/notfound.png';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../pages/pages.css'
function ChildImage(props) {
  return (
    <LazyLoadImage
      alt={"Child Photo"}
      className="childimage"
      //   effect="blur"
      width={300} height={300}
      // delayTime={60000}
      src={props.url || loadingImg}
    />
  );
}
export default ChildImage;
