import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import profile from "../images/FH-logo.svg";
import { useEffect } from "react";
import JWTInterceptor from "../service";
import Loader from "../components/Loader";
function Loginpage() {
  const Navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [EmailEror, setEmailEror] = useState(false);
  const [PassError, setPassError] = useState(false);
  const [ResponseError, setPassResponseError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailEror(false);
    setPassResponseError(false);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setPassError(false);
    setPassResponseError(false);
  };
  const formValidation = () => {
    if (document.getElementsByName("mail")[0].value === "") {
      setEmailEror(true);
    } else {
      setEmailEror(false);
    }

    if (document.getElementsByName("psd")[0].value === "") {
      setPassError(true);
    } else {
      setPassError(false);
    }
    if (
      document.getElementsByName("mail")[0].value === "" ||
      document.getElementsByName("psd")[0].value === ""
    ) {
      return false;
    }
    return true;
  };
  async function Login(e) {
    e.preventDefault();
    setLoading(true);
    if (formValidation()) {
      let loginDetails = {
        userName: Email,
        password: Password
      };
      JWTInterceptor.post("/Login", loginDetails, {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET"
        }
      })
        .then((res) => {
          // console.log("res", res);
          if (res.data.serviceResponse.success) {
            localStorage.setItem("isLogged", true);
            localStorage.setItem("tokne", res.data.serviceResponse.token);
            Navigate("/links");
          } else {
            setPassResponseError(true);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <div className="container">
      <Loader show={loading} />
      <form className="formcontain" action="post">
        <div className="">
          <img src={profile} width="50%"></img>
        </div>

        <div className="user-name">
          <label for="user">User Name</label>
          <div className="container-1">
            <input
              type="text"
              id="user"
              value={Email}
              onChange={handleEmail}
              name="mail"
              placeholder="Type Your Username"
            />
          </div>
          {EmailEror && <h6 style={{ color: "red" }}>Required</h6>}
        </div>
        <div className="password">
          <label for="password">Password</label>
          <div className="container-1">
            <input
              type="password"
              id="password"
              value={Password}
              onChange={handlePassword}
              placeholder="Password"
              name="psd"
              placeholder="Type Your Password"
            />
            {/* <a href="#">Forgot?</a> */}
          </div>
          {PassError && <h6 style={{ color: "red" }}>Required</h6>}
        </div>
        {ResponseError && (
          <h6 style={{ color: "red" }}>Incorrect Username/Password</h6>
        )}
        <div className="sign">
          <button onClick={Login}>Sign-In</button>
          {/* <a href="#">Need Account?</a> */}
        </div>
      </form>
    </div>
  );
}

export default Loginpage;
