import axios from "axios";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState, useMemo } from "react";
import ReactGA from "react-ga4";
import thankyou from "../images/success-page-heart.svg";

import JWTInterceptor from "../service";
import "./pages.css";

const SponserSucess = () => {
  const Location = useLocation();
  const Navigate = useNavigate();
  const [Flag, SetFlag] = useState(Location.search.split("?"));
  const [childName, SetChildName] = useState("");
  const [locationurl, SetLocationurl] = useState("");
  let decoded = decodeURIComponent(Flag[2]);
  let decodedone = decodeURIComponent(Flag[1]);
  let sourcecode = decoded[0].transactionsourcecode;
  useEffect(() => {
    let prevUrl = document.referrer.split("?")[1];
    if (
      decodedone.indexOf("action") !== -1 &&
      prevUrl !== window.location.search.split("?")[1]
    ) {
      window.open(window.location.pathname + window.location.search, "_parent");
      // Navigate(window.location.pathname+window.location.search,)
    }
  }, []);
  useEffect(() => {
    document.title = "FH Artist Event: Thank you!";
    // Send pageview event to Google Analytics
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search
    });
  }, []);
  // const [source,setsource] =useState()
  useEffect(() => {
    try {
      // let decoded = decodeURIComponent(Flag[2]);
      // console.log(decoded);

      if (Flag[1].indexOf("response") !== -1) {
        decoded = JSON.parse(decoded);
        let customData = decoded[0].custom_data;
        //customData = "{'Cart':[{'Amt':'38','Freq':'M','Pledge':'0104010345','FirstName':'Kevin','LastName':'Wilson','Email':'kevinwwilson@yahoo.com','Phone':'7193607156','City':'Colorado Springs','Address':'935 Grey Eagle Circle North','State':'CO','ZipCode':'80919','ChildName':'Antonio Luis'}]}";
        customData = customData.replace(/'/g, '"');
        //console.log('customData', customData)
        customData = JSON.parse(customData);
        // console.log("customData,", customData, customData.ChildName);
        SetChildName(customData.Cart[0].ChildName.replace(/~/g, "'"));
        let postBody = {
          SourceCode: decoded[0].transactionsourcecode,
          BluefinTransationID: decoded[0].transaction_id,
          CardBrand: decoded[0].card_brand,
          NameOnCard: "",
          MaskedCardNumber:
            decoded[0].firstnumber + "xxxxxxxxxxx" + decoded[0].last4,
          PhoneType: "CELL",
          CardExpiration: decoded[0].card_expiration,
          CardType: "CC",
          Title: "",
          LastName: customData.Cart[0].LastName.replace(/~/g, "'"),
          FirstName: customData.Cart[0].FirstName.replace(/~/g, "'"),
          Address: customData.Cart[0].Address.replace(/~/g, "'") || "",
          City: customData.Cart[0].City.replace(/~/g, "'") || "",
          State: customData.Cart[0].State || "",
          ZipCode: customData.Cart[0].ZipCode || "",
          Email: customData.Cart[0].Email || "",
          Phone: customData.Cart[0].Phone || "",
          DOB: new Date().toISOString(),
          BillingAddress: "",
          BillingCity: "",
          BillingState: "",
          BillingZipCode: "",
          BillingPhone: "",
          BillingPhoneType: "",
          BillingEmail: "",
          TransactionDate: decoded[0].transaction_timestamp.split(" ")[0],
          CustomID: decoded[0].custom_id,
          LstDonationDetails: [
            {
              PledgeableId: customData.Cart[0].Pledge,
              Amount: customData.Cart[0].Amt,
              DonationFrequency: customData.Cart[0].Freq
            }
          ]
        };

        JWTInterceptor.post("/SaveTransaction", postBody, {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,

            "Content-Type": "application/json",

            "Access-Control-Allow-Origin": "*",

            "Access-Control-Allow-Methods": "GET"
          }
        })
          .then((res) => {
            // console.log("res", res);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (Flag[1].indexOf("declined") !== -1) {
        decoded = JSON.parse(decoded);
        let sourcecode = decoded[0].transactionsourcecode;
        let customData = decoded[0].custom_data;
        customData = customData.replace(/'/g, '"');
        customData = JSON.parse(customData);
        let homeUrl =
          localStorage.getItem("single") === "true"
            ? process.env.REACT_APP_HOME_URL +
              sourcecode +
              "&PledgeableId=" +
              customData.Cart[0].Pledge
            : process.env.REACT_APP_HOME_URL + sourcecode;
        SetLocationurl(homeUrl);
        SetChildName(customData.Cart[0].ChildName.replace(/~/g, "'"));
        let postBody = {
          SourceCode: decoded[0].transactionsourcecode,
          BluefinTransationID: decoded[0].transaction_id,
          CardBrand: decoded[0].card_brand,
          NameOnCard: "",
          MaskedCardNumber:
            decoded[0].firstnumber + "xxxxxxxxxxx" + decoded[0].last4,
          PhoneType: "CELL",
          CardExpiration: decoded[0].card_expiration,
          CardType: "CC",
          Title: "",
          LastName: customData.Cart[0].LastName.replace(/~/g, "'"),
          FirstName: customData.Cart[0].FirstName.replace(/~/g, "'"),
          Address: customData.Cart[0].Address.replace(/~/g, "'") || "",
          City: customData.Cart[0].City.replace(/~/g, "'") || "",
          State: customData.Cart[0].State || "",
          ZipCode: customData.Cart[0].ZipCode || "",
          Email: customData.Cart[0].Email || "",
          Phone: customData.Cart[0].Phone || "",
          DOB: new Date().toISOString(),
          BillingAddress: "",
          BillingCity: "",
          BillingState: "",
          BillingZipCode: "",
          BillingPhone: "",
          BillingPhoneType: "",
          BillingEmail: "",
          CustomID: decoded[0].custom_id,
          TransactionDate: decoded[0].transaction_timestamp.split(" ")[0],
          LstDonationDetails: [
            {
              PledgeableId: customData.Cart[0].Pledge,
              Amount: customData.Cart[0].Amt,
              DonationFrequency: customData.Cart[0].Freq
            }
          ]
        };

        JWTInterceptor.post("/LogTransaction", postBody, {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,

            "Content-Type": "application/json",

            "Access-Control-Allow-Origin": "*",

            "Access-Control-Allow-Methods": "GET"
          }
        })
          .then((res) => {
            // console.log("res", res);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        decoded = decodeURIComponent(decodedone).split("&");
        let sourcecode = decoded[4].split("=")[1];
        let customData = decodeURIComponent(decoded[7].split("=")[1]);
        customData = customData.replace(/%3A/g, ":");
        customData = customData.replace(/%2C/g, ",");
        customData = customData.replace(/'/g, '"');
        customData = JSON.parse(customData);
        let homeUrl =
          localStorage.getItem("single") === "true"
            ? process.env.REACT_APP_HOME_URL +
              sourcecode +
              "&PledgeableId=" +
              customData.Cart[0].Pledge
            : process.env.REACT_APP_HOME_URL + sourcecode;
        SetLocationurl(homeUrl);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, []);
  return (
    <div className="sponsor-sucess">
      {Flag[1].indexOf("response") !== -1 && (
        <>
          <h1 className="heading">THANK YOU!</h1>

          <center>
            {" "}
            <img src={thankyou} alt="success heart" width="130" />
          </center>

          <h2 className="thanks-content">
            {`THANK YOU FOR BRINGING HOPE TO`} <span>{childName}</span>
          </h2>

          {/*  <p className="thanks-description">
            {`You’re making it possible for`} <br />
            {childName}’s community to help <br />
            children flourish{" "}
          </p> */}

          <br />

          <div className="email-conformation">
            {/* <p >We’ve emailed a confirmation to you.</p> */}
            {/* <hr/> */}
            {/* <p>Your Welcome Kit will arrive soon in the mail.</p> */}
            <p>Look for a Welcome Kit to arrive in the mail soon.</p>
            {/* <span>{`Please pray for ${childName}!`} </span> */}
          </div>
          <br />
          {/*   <p className="thanks-description">
          SPONSORED THROUGH THE QR ON A PACKET? <br/> PLEASE TAKE THAT HOME WITH YOU TONIGHT!
          </p> */}
          <h2 className="thanks-content">
            SPONSORED THROUGH THE QR ON A PACKET? PLEASE TAKE THAT HOME WITH YOU
            TONIGHT!
          </h2>
        </>
      )}
      {Flag[1].indexOf("declined") !== -1 && (
        <>
          <h3>Payment Declined!</h3>

          <a
            className="findout"
            style={{ cursor: "pointer" }}
            href={locationurl}
          >
            Go to home page
          </a>
        </>
      )}
      {decodedone.indexOf("action") !== -1 && (
        <>
          <h3>Payment Cancelled!</h3>
          <div className="findoutContainer">
            <a
              className="findout"
              style={{ cursor: "pointer" }}
              href={locationurl}
              st
            >
              Go to home page
            </a>
          </div>
        </>
      )}

      {/* <div className='bottomtext'>
                <p>For any enquiry, please contact us at:</p>
                <p>sponsor@fh.org  or  02532188812</p>
            </div> */}
    </div>
  );
};

export default SponserSucess;
