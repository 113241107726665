import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import "./pages.css";
import NotFoundImage from "../images/notFound.jpg";
import JWTInterceptor from "../service";
import { useSearchParams } from "react-router-dom";
import Counter from "../components/Counter.js";
import DatePicker from "react-datepicker";
import Loader from "../components/Loader";
import Header from "../components/Header";
import { ImCross } from "react-icons/im";
import profile from "../images/FH-logo.svg";
import "react-datepicker/dist/react-datepicker.css";
const SponsorInformation = () => {
  const Location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  // searchParams.get("ChildName")

  // const [email, setEmail] = useState('');
  // const [emailErr, setEmailErr] = useState(false);
  // const validate = () => {
  //    if (!validEmail.test(email)) {
  //       setEmailErr(true);
  //    }

  const [inputValue, setInputValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirmemail: "",
    phoneNumber: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
    dob: "",
  });
  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    // confirmemail: false,
    phoneNumber: false,
    street_address: false,
    city: false,
    state: false,
    zip: false,
    // dob: false,
  });
  const [errorMsg, seterrorMsg] = useState("");
  let errorObj = {
    first_name: false,
    last_name: false,
    email: false,
    // confirmemail: false,
    phoneNumber: false,
    street_address: false,
    city: false,
    state: false,
    zip: false,
    // dob: false,
  };

  let valid = false;
  const handleNext = () => {
    // ReactGA.event({
    //     category: 'Button',
    //     action: 'Click',
    //     label: 'My Button'
    //   });
    // console.log(inputValue);
    // let errorObj = {
    //     first_name: false,
    //     last_name: false,
    //     email: false,
    //     phoneNumber: false,
    //     street_address: false,
    //     city: false,
    //     state: false,
    //     zip: false
    // }
    // for (const property in errorObj) {
    //     if (inputValue[property].length === 0) {
    //         errorObj[property] = true
    //     }
    // }
    //  const valid=   Validate(inputValue)

    // setError(errorObj);

    Validate(inputValue);
    if (valid === true) {
      setLoading(true);
      ReactGA.event({
        category: "User",
        action: "save_personal_info",
      });
      // if (Object.values(errorObj).filter((ele) => Boolean(ele)).length === 0) {
      JWTInterceptor.get(`GetUniqueCustomID`, {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => {
          // console.log(res);
          // let queryString = "";
          // for (const property in inputValue) {
          //     queryString = queryString + property + '=' + inputValue[property].trim() + '&'
          // }
          let fname = encodeURIComponent(
            inputValue.first_name.replace(/'/g, "~")
          );
          let lname = encodeURIComponent(
            inputValue.last_name.replace(/'/g, "~")
          );
          let aname = encodeURIComponent(
            inputValue.street_address.replace(/'/g, "~")
          );
          let cityname = encodeURIComponent(inputValue.city.replace(/'/g, "~"));
          let childname = encodeURIComponent(
            searchParams.get("ChildName").replace(/'/g, "~")
          );
          let finalString = `${
            process.env.REACT_APP_PAYCONEX_URL
          }&transactionsourcecode=${Location.state.code}&amount=38&custom_id=${
            res.data.serviceResponse.customID
          }&first_name=${fname}&last_name=${lname}&custom_data=%7B%27Cart%27:%5B%7B%27Amt%27:%2738%27,%27Freq%27:%27M%27,%27Pledge%27:%27${
            Location.state.pledgeableId
          }%27,%27FirstName%27:'${fname}',%27LastName%27:'${lname}',%27Email%27:'${
            inputValue.email
          }',%27Phone%27:'${
            inputValue.phoneNumber //%27DOB%27:'${inputValue.dob}'
          }',%27City%27:'${cityname}',%27Address%27:'${aname}',%27State%27:'${
            inputValue.state
          }',%27ZipCode%27:'${
            inputValue.zip
          }',%27ChildName%27:'${childname}'%7D]%7D`;
          // window.location.assign(finalString);
          // navigate({ pathname: '/payment', search: `?SourceCode=${Location.state.code}`,state:{finalString } });
          navigate(`/payment?SourceCode=${Location.state.code}`, {
            state: {
              finalString,
            },
          });
          saveDetails(res.data.serviceResponse.customID);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const saveDetails = (customID) => {
    Validate(inputValue);
    let postBody = {
      firstName: inputValue.first_name,
      lastName: inputValue.last_name,
      address: inputValue.street_address,
      city: inputValue.city,
      state: inputValue.state,
      zipCode: inputValue.zip,
      phone: inputValue.phoneNumber,
      email: inputValue.email,
      pledgeableId: Location.state.pledgeableId,
      SourceCode: Location.state.code,
      customID: customID,
    };
    if (valid === true) {
      // if (Object.values(errorObj).filter((ele) => Boolean(ele)).length === 0) {
      JWTInterceptor.post("/SaveSponsorDetail", postBody, {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,

          "Content-Type": "application/json",

          "Access-Control-Allow-Origin": "*",

          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {});
    }
  };
  function Validate(inputValue) {
    let errorDisplay = {};
    // console.log(inputValue);

    if (!inputValue.first_name) {
      errorDisplay.first_name = "first_name is required";
      errorObj.first_name = true;
    } else errorObj.first_name = false;

    if (!inputValue.last_name) {
      errorDisplay.last_name = "last_name is required";
      errorObj.last_name = true;
    } else errorObj.last_name = false;
    //email
    if (!inputValue.email) {
      errorDisplay.email = "email is required";
      errorObj.email = true;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValue.email)
    ) {
      errorDisplay.email = " Invalid email ";
      errorObj.email = true;
    } else errorObj.email = false;

    if (!inputValue.street_address) {
      errorDisplay.street_address = "street_address is required";
      errorObj.street_address = true;
    } else errorObj.street_address = false;

    //confirmmail
    // if (!inputValue.confirmemail) {
    //   errorDisplay.confirmemail = "confirmEmail is required";
    //   errorObj.confirmemail = true;
    // } else if (
    //   !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
    //     inputValue.confirmemail
    //   )
    // ) {
    //   errorDisplay.confirmemail = "Invalid Email ";
    //   errorObj.confirmemail = true;
    // } else if (inputValue.confirmemail != inputValue.email) {
    //   errorDisplay.confirmemail = "Emails doesn't match ";
    //   errorObj.confirmemail = true;
    // } else errorObj.confirmemail = false;

    //city

    if (!inputValue.city) {
      errorDisplay.city = "city is required";
      errorObj.city = true;
    } else errorObj.city = false;

    if (!inputValue.state) {
      errorDisplay.state = "state is required";
      errorObj.state = true;
    } else errorObj.state = false;
    // phonenumber
    if (!inputValue.phoneNumber) {
      errorDisplay.phoneNumber = "phoneNumber is required";
      errorObj.phoneNumber = true;
    } else if (!/^\d{10}$/.test(inputValue.phoneNumber)) {
      errorDisplay.phoneNumber = " Invalid phoneNumber ";
      errorObj.phoneNumber = true;
    } else errorObj.phoneNumber = false;

    //Zip
    if (!inputValue.zip) {
      errorDisplay.zip = "zip is required";
      errorObj.zip = true;
    } else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(inputValue.zip)) {
      errorDisplay.zip = " Invalid zip ";
      errorObj.zip = true;
    } else errorObj.zip = false;
    //date
    // if (!inputValue.dob) {
    //   errorDisplay.dob = "dob is required";
    //   errorObj.dob = true;
    // }
    //    else if(!/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(inputValue.dob)){
    //     errorDisplay.dob=" Invalid dob ";
    //     errorObj.dob=true;
    //    }
    // else errorObj.dob = false;
    setError(errorObj);
    // console.log(errorObj);

    if (
      !errorDisplay.first_name &&
      !errorDisplay.last_name &&
      !errorDisplay.email &&
      !errorDisplay.phoneNumber &&
      !errorDisplay.zip &&
      !errorDisplay.city &&
      !errorDisplay.street_address &&
      !errorDisplay.state
      //   !errorDisplay.confirmemail &&
      //   !errorDisplay.dob
    ) {
      seterrorMsg("");
      valid = true;
    } else if (errorDisplay) {
      seterrorMsg(errorDisplay);
      valid = false;
      return false;
    } else {
      seterrorMsg("");
      valid = true;
    }
    return true;
  }
  // useEffect(()=>{
  //     Validate(inputValue)
  // },[inputValue])
  useEffect(() => {
    document.title = "FH Artist Event: Your Info";
    // Send pageview event to Google Analytics
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    console.log("estdrfghjnkm");
  }, []);
  const InputHandler = (e) => {
    let currentForm = { ...inputValue };
    // let { name, value } = e.target
    // currentForm[name] = value;
    // setInputValue(currentForm);
    // if (value.length !== 0 && (name === 'first_name' || name === 'last_name' || name === 'email'
    //     || name === 'phoneNumber' || name === 'street_address' || name === 'city' || name === 'state' || name === 'zip')) {
    //    if(name.email)
    //         setError((prev) => ({
    //         ...prev,
    //         [name]: false

    //     }))
    // }
    let { name, value } = e.target;
    currentForm[name] = value;
    setInputValue(currentForm);
    Validate(currentForm);
    // setInputValue((prev)=>{
    //     return{
    //         ...prev,
    //         [name]:value
    //     }

    // })

    // Validate(value)
  };

  return (
    <div>
      <div>
        <p className="infobold">
          <h1>
            {" "}
            HELP <span> {searchParams.get("ChildName")}</span> AND THEIR
            COMMUNITY FLOURISH
          </h1>
        </p>
      </div>
      {/* <p>{`Sponsor ${searchParams.get("ChildName")}`}</p> */}
      <p className="sp-desc">
        with your <b>$38 monthly </b>sponsorship.
      </p>
      {/* <div className='form-img'>
                <img src={Location.state.url || NotFoundImage} alt='child' />
            </div> */}
      <p className="getinfo_heading">
        <b>Please provide your information:</b>
      </p>
      <form
        className="sponsorform"
        method="post"
        action="/form"
        autoComplete="on"
      >
        <div>
          <input
            name="first_name"
            style={{ width: "100%" }}
            className={`sponsor_input ${error.first_name && "input-err"}`}
            placeholder="first name*"
            value={inputValue.first_name}
            onChange={(e) => {
              InputHandler(e);
            }}
          />
          {/* onBlur={()=>{Validate(inputValue)}}  */}

          {/* <p className='error'>{errorMsg.first_name}</p> */}
          {/* <small>Mandatory Field</small> */}
        </div>
        <div>
          <input
            name="last_name"
            style={{ width: "100%" }}
            className={`sponsor_input ${error.last_name && "input-err"}`}
            placeholder="Last Name*"
            value={inputValue.last_name}
            onChange={(e) => {
              InputHandler(e);
            }}
          />
          {/* <p className='error'>{errorMsg.last_name}</p> */}
        </div>
        <div>
          <input
            name="street_address"
            style={{ width: "100%" }}
            className={`sponsor_input ${error.street_address && "input-err"}`}
            placeholder="Mailing Address (include unit number)*"
            value={inputValue.street_address}
            onChange={(e) => {
              InputHandler(e);
            }}
          />
          {/* <p className='error'>{errorMsg.street_address}</p> */}
        </div>
        <div>
          <input
            name="city"
            style={{ width: "100%" }}
            className={
              !error.city
                ? "sponsor_input city_iput"
                : "sponsor_input city_iput input-err"
            }
            placeholder="city*"
            value={inputValue.city}
            onChange={(e) => {
              InputHandler(e);
            }}
          />
          {/* <p className='error'>{errorMsg.city}</p> */}
        </div>
        <div className="flexdiv">
          <select
            name="state"
            id="select-state"
            className={
              !errorMsg.state
                ? "sponsor_input state_input"
                : "sponsor_input state_input input-err"
            }
            placeholder="state*"
            value={inputValue.state}
            onChange={(e) => {
              InputHandler(e);
            }}
            style={{ marginRight: "10px", padding: "1px" }}
          >
            <p className="error">{errorMsg.state}</p>
            <option value="" disabled selected hidden>
              State*
            </option>
            <option class="testt" value="AL">
              Alabama
            </option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
          {/* <p>{errorMsg.state}</p> */}
          <input
            name="zip"
            type="number"
            id="state_input"
            className={`sponsor_input ${error.zip && "input-err"}`}
            placeholder="ZIP*"
            value={inputValue.zip}
            onChange={(e) => {
              InputHandler(e);
            }}
            style={{ marginLeft: "10px" }}
          />
          {/* <p className='error'>{errorMsg.zip}</p> */}
          {/* <input name="state" className={!errorMsg.state ? 'sponsor_input state_input' : 'sponsor_input state_input input-err'} placeholder='STATE' value={inputValue.state}
                onChange={(e) => { InputHandler(e) }} /> */}
        </div>

        <div>
          <input
            name="phoneNumber"
            type="number"
            style={{ width: "100%" }}
            className={`sponsor_input ${error.phoneNumber && "input-err"}`}
            placeholder="phone*"
            value={inputValue.phoneNumber}
            onChange={(e) => {
              InputHandler(e);
            }}
          />
          {/* onBlur={()=>{Validate(inputValue)}} */}
          {/* <p className='error'>{errorMsg.phoneNumber}</p> */}
        </div>

        <div>
          <input
            name="email"
            style={{ width: "100%" }}
            className={`sponsor_input ${error.email && "input-err"}`}
            placeholder="email*"
            value={inputValue.email}
            onChange={(e) => {
              InputHandler(e);
            }}
          />
          {/* {errorMsg.email?  <p className='error'>{errorMsg.email}</p> : <p>&nbsp;</p>} */}
        </div>
        {/* <div>
          <input
            name="confirmemail"
            className={`sponsor_input ${error.confirmemail && "input-err"}`}
            placeholder="confirm email*"
            value={inputValue.confirmemail}
            onChange={(e) => {
              InputHandler(e);
            }}
          /> */}
        {/* {errorMsg.email?  <p className='error'>{errorMsg.email}</p> : <p>&nbsp;</p>} */}
        {/* </div> */}
        <div>
          {/* <DatePicker name='dob' className={`dateofbirth ${error.dob && 'input-err'}`} dateFormat={'yyyy/MM/dd'}
      selected={inputValue.dob} value={inputValue.dob}
      onChange={(e) => { InputHandler({target:{name:"dob",value:e}}) }}
    //   isClearable
   
      placeholderText="Date of birth*"
    /> */}
          {/* <input type="text" name='dob'  className={`sponsor_input ${error.dob && 'input-err'}`} placeholder='date of birth*'  value={inputValue.dob}
                    onChange={(e) => { InputHandler(e) }} /> */}
          {/* {errorMsg.email?  <p className='error'>{errorMsg.email}</p> : <p>&nbsp;</p>} */}
          {/* pattern="\d{2}/\d{2}/\d{4}" maxlength="10" title="Please enter a date in the format dd/mm/yyyy" */}
          {/* onChange={(e)=>formatInputDate(e,this)} */}
        </div>
      </form>
      <div className="btnnext">
        <a
          href="javascript:void(0)"
          className="btn"
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </a>
        {/* <button className='btn' onClick={handleNext}>Next</button> */}
      </div>
      <span style={{fontFamily: 'Grotest-normal'}}>
        By submitting this form, you agree to our{" "}
        <span className="privacy-policy" onClick={() => setShowPopUp(true)}>
          Privacy Policy
        </span>{" "}
        on collecting and using your personal information.
      </span>
      {/* <div class="timer">
      <p>TIME LEFT: <span>  <Counter className="counter" /> </span></p>
      </div>  */}
      {showPopUp && (
        <div className="pop-up">
          <div className="box">
            <div className="pop-up-header">
              <img  src={profile} width="50%"/>
              <ImCross onClick={() => setShowPopUp(false)} className="cross-icon" />
            </div>
            <div className="policy-details">
              <div className="privacy-policy-header">PRIVACY POLICY</div>
              <p>
                Thank you for choosing to partner with us in ministry. Your
                trust and confidence is important to us. The following privacy
                policy is to protect and secure the personal information you
                provide to our ministry. This policy covers information you
                provide online as well as information you may provide to us via
                other offline means.
              </p>

              <h4>KEEPING YOU AWARE</h4>
              <p>
                Food for the Hungry provides this policy so that you can be
                aware of our security practices and the way your personal
                information is collected and used. Changes to this privacy
                policy will be sent to the email in your account. You also are
                provided with an opportunity to remove your name from our
                mailing lists, if you so choose
              </p>
              <h4>YOUR INFORMATION - WHAT WE COLLECT</h4>
              <p>Instances in which your information may be collected:</p>
              <p>
                <ul className="p-ul">
                  <li>
                    If you e-mail us or fill out an online form requesting
                    information or materials from our organization.
                  </li>
                  <li>
                    If you fill out an online application to participate in one
                    of our mission's opportunities such as: volunteer hunger
                    advocate, short-term teams missions, or hunger corps
                    missions. This may also include applying for employment
                    online.
                  </li>
                  <li>
                    If you subscribe to an online subscription such as an email
                    newsletter or email prayer network.
                  </li>
                  <li>
                    If you make a donation or sponsor a child online, via mail,
                    telephone or at an event.
                  </li>
                </ul>
              </p>
              <p>
                The online information we collect from you may include your
                name, address, city, state, zip, telephone number(s), email
                address, and when making a donation, a credit card number and
                expiration date.
              </p>
              <p>
                The information you provide when filling out an online
                opportunity application will vary depending upon the position
                you are interested in
              </p>
              <p>
                In order to improve and enhance our online services, we may also
                periodically ask you to provide voluntary information such as
                child sponsorship testimonials, contact preferences, online
                suggestions, or information regarding your demographic
                information.
              </p>
              <p>The information you provide may be stored indefinitely.</p>
              <h4>HOW WE USE YOUR INFORMATION</h4>
              <p>
                Your information is used to benefit your involvement in our
                ministry:
              </p>
              <p>
                <ul className="p-ul">
                  <li>Enabling you to complete a transaction online</li>
                  <li>Issuing you an income tax receipt for your donation</li>
                  <li>Updating you on giving and ministry opportunities</li>
                </ul>
              </p>
              <p>
                Credit card numbers are only used for processing payments of
                your donation and/or sponsorship pledges. Donor giving
                information is kept on file for IRS requirements and general
                business purposes to analyze overall giving patterns, assist
                budget projections, as well as to understand donors’ interests
                in our mission. We also provide you the opportunity to remove
                your name from our mailing list (please review the corresponding
                section of this privacy policy below).
              </p>
              <h4>COOKIES</h4>
              <p>
                Cookies are small digital signature files that are stored by
                your web browser that allow your preferences to be recorded when
                visiting the website. Also they may be used to track your return
                visits to the website.
              </p>
              <p>
                3rd party advertising companies may also use cookies for
                tracking purposes.
              </p>
              <h4>THIRD PARTY COOKIES</h4>
              <p>
                Notwithstanding anything else in this policy, we and/or our
                partners may place or read use cookies to enable you to receive
                customized ads or content. These cookies contain no personally
                identifiable information. The cookies may reflect de-identified
                demographic or other data linked to data you voluntarily have
                submitted to us, e.g., your email address, which we may share
                with a data provider solely in hashed, non-human readable form.
              </p>
              <p>
                We and/or our partners may also use cookies for delivering
                personalized advertising emails. These cookies are used to
                identify the visitors of our advertisers’ websites and send
                personalized emails based on the visitors’ browsing experience.
                To learn more click here. To opt out, click here.
              </p>
              <p>
                We and/or our partners use cookies and web beacon technology to
                associate certain Internet-related information about you, such
                as your Internet Protocol address and what Web browser you are
                using, with certain of your online behaviors, such as opening
                emails or browsing websites. Such information is used to
                customize ads or content and may be shared with our partners.”
              </p>
              <h4>HOW WE PROTECT YOUR INFORMATION</h4>
              <p>
                The first protection we ensure is that Food for the Hungry never
                sells, rents or exchanges your personal information with any
                individual or organization for any purpose, ever. Nor do we
                share names or contact information externally without
                permission. This policy applies to all information received by
                the Food for the Hungry, both online and offline, as well as any
                electronic, written, or oral communications. Use of your
                information is restricted to persons employed or contracted by
                Food for the Hungry who agree to abide by our pledge of
                confidentiality.
              </p>
              <p>
                Food for the Hungry makes every effort to ensure the security of
                your information. To prevent unauthorized access, maintain data
                accuracy, and ensure the proper use of information, we have
                established and implemented appropriate physical, electronic,
                and managerial procedures to safeguard and secure the
                information we collect online.
              </p>
              <p>
                Food for the Hungry uses Internet Encryption Software, Transport
                Layer Security (TLS) Protocol when collecting or transferring
                sensitive data such as credit card information. Any information
                you enter is encrypted at your browser, sent over the public
                Internet in encrypted form, then tokenized to permanently remove
                access to your credit card information in our database. Food for
                the Hungry adheres to the PCI Security Standards.
              </p>
              <h4>REMOVING YOUR NAME FROM OUR MAILING LIST</h4>
              <p>
                You can easily reduce the amount of communication you are
                receiving or be removed from any future communications from our
                ministry by contacting us at <span style={{color: '#aebc37'}}>donorhelp@fh.org </span> or calling us at
                1-866-307-3259.
              </p>
              <p>
                If you determine that information in our database is inaccurate
                or it has changed, you can easily modify your personal
                information at give.fh.org or by calling us at 1-866-307-3259.
              </p>
              <p>
                <b>MAILING ADDRESS</b>
              </p>
              <p>
                Food for the Hungry <br /> 2 N Central Avenue Suite 200 <br /> Phoenix, AZ
                85004
              </p>
              <p>
                <b>PHONE</b>
              </p>
              <p>1-866-307-3259</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SponsorInformation;
