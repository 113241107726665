import React from "react";
import "./header.css";
const Loader = (props) => {
  return (
    <>
      {props.show && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default Loader;
