import axios from 'axios';
const JWTInterceptor = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 6 * 60 * 1000, //6mins
});

const requestHandler = (request) => {

  //   let token = `${process.env.REACT_APP_API_Client_ID}:${process.env.REACT_APP_API_Client_SECRET}`;
  //   token = Buffer.from(token).toString('base64');
  //   request.headers.Authorization = `Basic ${token}`;

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = '/login';
  }

  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

JWTInterceptor.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
JWTInterceptor.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
export default JWTInterceptor;
