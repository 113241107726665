import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import "./pages.css";
import data from "../Data/GridData.json";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Paginator from "../components/Paginator";
import JWTInterceptor from "../service";
import { BsSearch } from "react-icons/bs";
const SponsorshipCsv = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [GridData, setGridData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalData, setTotalData] = useState([]);
  const [SerachBoxData, setSerachBoxData] = useState("");
  let PageSize = 10;
  const GridDataPagination = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return GridData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, GridData]);
  useEffect(() => {
    if (!localStorage.getItem("isLogged")) {
      Navigate("/login");
    }
  }, []);
  useEffect(() => {
    CsvGrid(false);
  }, []);
  useEffect(() => {
    let filterData = TotalData.filter((ele) => {
      return ele.fileName.toLowerCase().includes(SerachBoxData.toLowerCase());
    });
    setGridData(filterData);
  }, [SerachBoxData]);
  const CsvGrid = (generate) => {
    setLoading(true);
    let csvDataSend = {
      isGenerate: generate,
      token: localStorage.getItem("tokne")
    };
    JWTInterceptor.post("/ExportSponsorshipCSV", csvDataSend, {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET"
      }
    })
      .then((res) => {
        // console.log("res", res.data.serviceResponse.lstExportSponsorshipHistory.length);
        if (res.data.serviceResponse.success) {
          setGridData(res.data.serviceResponse.lstExportSponsorshipHistory);
          setTotalData(res.data.serviceResponse.lstExportSponsorshipHistory);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const SearchHandler = (e) => {
    setSerachBoxData(e.target.value);
    setCurrentPage(1)
  };
  return (
    <>
      <Loader show={loading} />
      <Header showLogOut={true} />
      <h2 className="headings">Export Sponsorship CSV</h2>
      <hr className="links-hr"></hr>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "10px",
          marginTop: "10px",
          marginRight: "10px",
          marginBottom: "10px"
        }}
      >
        <button className="load-unload" onClick={() => CsvGrid(true)}>
          Generate Sponsorship CSV
        </button>
        <input
          type="text"
          placeholder="Search By Name"
          onChange={SearchHandler}
        ></input>
      </div>
      <div className="grid">
        <div>
          <div className="inner-grid grid-heading">
            <div>File Name</div>
            <div>Number of Sponsorships</div>
            <div>Created Date</div>
            <div>Action</div>
          </div>
        </div>
        {GridDataPagination.map((ele, index) => {
          return (
            <div className="inner-grid" key={ele["fileName"]}>
              <div style={{ marginLeft: "10px", textAlign: "start" }}>
                {ele["fileName"]}
              </div>
              <div>{ele["numberOfSponsorship"]}</div>
              <div>{ele["createdDate"].split("T")[0]}</div>
              <div className="action-btn">
                <button
                  className="load-unload"
                  onClick={() => window.location.replace(ele["fileURL"])}
                >
                  Download
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <Paginator
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={GridData.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default SponsorshipCsv;
