import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import "./pages.css";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Paginator from "../components/Paginator";
import JWTInterceptor from "../service";

const QRCodeHistory = () => {
  const [loading, setLoading] = useState(false);
  const [HistoryData, setHistoryData] = useState([]);
  useEffect(() => {
    setLoading(true);
    let historyBody = {
      token: localStorage.getItem("tokne"),
    };
    JWTInterceptor.post("/GetQRCodeGenerationDetail", historyBody, {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
      },
    })
      .then((res) => {
        console.log("res", res);
        if (res.data.serviceResponse.success) {
          setHistoryData(res.data.serviceResponse.lstQRCodeGenerationDetails);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Loader show={loading} />
      <Header showLogOut={true} />
      <h2 className="headings">QR Code Generation History</h2>
      <hr className="links-hr"></hr>
      <div className="grid">
        <div>
          <div className="inner-grid grid-heading">
            <div>Checkout Batch</div>
            <div>QR Type</div>
            <div>Status</div>
            <div>Number of QR Code</div>
            <div>Updated Date</div>
            <div>Error</div>
            <div>Action</div>
          </div>
        </div>
        {HistoryData.map((ele, index) => {
          return (
            <div className="inner-grid-history" key={ele["sourceCode"]}>
              <div>{ele["sourceCode"]}</div>
              <div>{ele["qrType"]}</div>
              <div>{ele["status"]}</div>
              <div>{ele["numberOfQRCode"]}</div>
              <div>{ele["updatedDate"].split("T")[0]}</div>
              <div>
                {ele["errorMessage"] !== "" ? ele["errorMessage"] : "-"}
              </div>
              <div className="action-btn">
                {ele["fileURL"] !== "" ? (
                  <button
                    className="load-unload"
                    onClick={() => window.location.replace(ele["fileURL"])}
                  >
                    Download
                  </button>
                ) : (
                  "-"
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QRCodeHistory;
